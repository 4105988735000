import React from "react"

import SEO from "../components/seo"
import { FaArrowRight, FaEnvelope } from 'react-icons/fa'

import "../scss/contactus.scss"

import EmailIcon from "../assets/img/youpalGroup/contactUs/icon1.png"
import CallIcon from "../assets/img/youpalGroup/contactUs/icon2.png"
import SupportIcon from "../assets/img/youpalGroup/contactUs/icon3.png"

const ContactUsPage = () => {

  return (
    <div>
      <SEO title="Contact us" keywords={[`youpal`, `youpal group`]} />
      <section className="container contactUsPageSection">
        <h2>Thanks for contacting us,<br />your message will be attended to shortly.</h2>
        <p></p>
      </section>

      <section className="container contactsSection contactsSectiont">
        <div className="row">
          <div className="col iconP">
            <img src={EmailIcon} />
            <h3>Email us</h3>
            <p>
              If you have any additional or urgent queries reach us on the
              following email:
            </p>
            <p className="plink">
              <a href="mailto:request@youpalgroup.com">request@youpalgroup.com</a>
            </p>
          </div>
          <div className="col iconP">
            <img src={CallIcon} />
            <h3>Call us</h3>
            <p>
              If you really want to hear our voice, please reach out to the
              following number:
            </p>
            <p className="plink"><a href="tel:+4681-241-1707">+46 8-124 117 07</a></p>
          </div>
          <div className="col iconP">
            <img src={SupportIcon} />
            <h3>Support</h3>
            <p>
              You can also get lucky via contacting one of our amazing team
              members via live chat
            </p>
            <p className="plink">
              <a href="mailto:request@youpalgroup.com">
                <span style={{ marginRight: "20px" }}>Go chatting</span>
                <FaArrowRight style={{ fill: "#473BF0" }} />
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUsPage
